<template>
  <div style="background-color: #f7f7f7">
    <!-- <v-container class="mt-12" >
    <v-row class="pb-8">
      <v-col cols="12" lg="12" md="12" sm="12" class="pt-12 px-6">
        <p
          class="accent--text pt-2 caption"
          style="border-bottom: 1px solid black;
          padding-bottom: 5px;
          width: max-content;
          border-color: #a67d58;"
        >
          {{ $t("home.news.news") }}
        </p>
        <h2 class="accent--text ">
          {{ $t("home.news.OUR_LATEST_NEWS") }}
        </h2>
      </v-col>

      <v-col cols="12" lg="6" md="6" sm="12" class="px-6">
        <v-card
          @click="1"
          elevation="0"
          :class="{ 'on-hover': hover }"
          class="rounded-t-0"
        >
          <youtube
            width="100%"
            :video-id="videoId1"
            ref="youtube1"
            @playing="playing1"
          ></youtube>
        </v-card>
      </v-col>

      <v-col cols="12" lg="6" md="6" sm="12" class="pt-8 px-6">
        <h4 class="accent--text">
          <b>{{ $t("home.news.AlSalamResidentialComplex") }}</b>
        </h4>
        <p class="pt-2">OCT 15, 2022</p>
        <p class=" caption">
          {{ $t("home.news.AlSalamResidentialComplex_desc") }}
        </p>
      </v-col>
         <v-col cols="12" lg="6" md="6" sm="12" class="px-6 pt-8">
        <v-card
          @click="1"
          elevation="0"
          :class="{ 'on-hover': hover }"
          class="rounded-t-0"
        >
          <youtube
            width="100%"
            :video-id="videoId2"
            ref="youtube2"
            @playing="playing2"
          ></youtube>
        </v-card>
      </v-col>

      <v-col cols="12" lg="6" md="6" sm="12" class="pt-12 px-6">
        <h4 class="accent--text">
          <b>{{ $t("home.news.KadhimiyaMall") }}</b>
        </h4>
        <p class="pt-2">OCT 9, 2022</p>
        <p class=" caption">
          {{ $t("home.news.KadhimiyaMall_desc") }}
        </p>
      </v-col>
    </v-row>
  </v-container> -->
  </div>
</template>

<script>
export default {
  name: "Install",
  data: () => ({
    isActive: false,
    videoId: "TmOPUpBtvt0",
    videoId1: "TrLjhw5KOgI",
    videoId2: "4Xcl88Y3dXo",
  }),
  computed: {
    plugins() {
      return JSON.parse(JSON.stringify(this.$t("home.install.plugins")));
    },
    player() {
      return this.$refs.youtube.player;
    },
    player1() {
      return this.$refs.youtube1.player1;
    },
    player2() {
      return this.$refs.youtube2.player2;
    },
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playVideo1() {
      this.player1.playVideo1();
    },
    playVideo2() {
      this.player2.playVideo2();
    },
    playing() {
      console.log(" we are watching!!!");
    },
    playing1() {
      console.log(" we are watching!!!");
    },
    playing2() {
      console.log(" we are watching!!!");
    },
  },
};
</script>

<style lang="scss" scoped></style>
